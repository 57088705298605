export const classes = {
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    padding: '13px 16px 13px 25px'
  },
  container: {
    padding: { xs: '5vh 3vw 3vh', sm: '15vh 3vw 9.7vh'},
  },
  paper: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row'},
    marginBottom: '30px',
    padding: '30px 6.6vw 34px',
    borderRadius: '8px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  infoInstitution: {
    display: 'flex',
    flexDirection: { xs: 'row', sm: 'column'},
    alignSelf: { xs: 'baseline', sm: 'center'},
    alignItems: 'center',
    marginBottom: { xs: '1.5rem', sm: '0'},
    gap: '0.85rem',
  },
  logo: {
    width: 129,
    height: 'auto',
    maxHeight: 129,
  },
  informations: {
    display: 'flex',
    marginLeft: { xs: '0', sm: '7.9vw'},
    gap: '1.2rem'
  },
  button: {
    padding: '13.5px',
    color: '#E5E5E5'
  }
}