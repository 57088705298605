import React, { useContext } from "react";
import { DataContext } from "contexts/DataContext";
import { Layout, Button, Tooltip } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useNavigate, Navigate } from "react-router-dom";

const { Header, Content } = Layout;

const Viewer = () => {
  let navigate = useNavigate();

  const { data } = useContext(DataContext);

  const canView = Object.values(data).length;

  const handleReturn = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  if (!canView) return <Navigate to="/" />;

  return (
    <Layout>
      <Header>
        <div className="header__container">
          <h1 className="header__title">
            <a href="/" onClick={handleReturn}>
              Portal de Resultados
            </a>
          </h1>
          <div>
            <Tooltip title="Sair">
              <Button
                shape="circle"
                icon={<LogoutOutlined />}
                onClick={() => navigate("/")}
              />
            </Tooltip>
          </div>
        </div>
      </Header>
      <Content>
        <div style={{ height: "calc(100vh - 64px)" }}>
          <iframe
            style={{
              border: 0,
              width: "100%",
              height: "100%",
            }}
            src={data.viewerLink3d}
            title="Visualizador"
          />
        </div>
      </Content>
    </Layout>
  );
};

export default Viewer;
