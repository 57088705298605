import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { DataContext } from "contexts/DataContext";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import axios from "axios";

import { Worker } from '@react-pdf-viewer/core';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import brand from './contexts/brand';

import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Viewer from "./components/Viewer";
import Viewer3d from "./components/Viewer3d";


axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

const colorDefault = {'vet': '#75CCBE', 'human': '#FFECE0'}
const colorInput = {'vet': '#038F79', 'human': '#FF9F63'}

const theme = createTheme({
  palette: {
    background: {
      default: colorDefault[brand],
      paper: '#FFF'
    },
    primary: {
      main: '#130B1F',
    },
    border: {
      input: colorInput[brand]
    }
  },
  typography: {
    fontFamily: 'Open Sans',
    h1: {
      fontFamily: 'PT Serif',
      fontSize: { xs: '32px', sm: '4.2vh' },
      fontWeight: 700,
      color: '#130B1F'
    },
    h2: {
      fontFamily: 'PT Serif',
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'Open Sans',
      fontSize: '1.5rem',
      fontWeight: 600,
      color: '#130B1F'
    },
    h4: {
      marginBottom: '.5rem',
      fontFamily: 'PT Serif',
      fontSize: '1rem',
      fontWeight: 700,
      color: '#130B1F'
    },
    h5: {
      fontFamily: 'Open Sans',
      fontSize: '2.1vh',
      fontWeight: 600,
      color: '#130B1F',
    },
    h6: {
      fontFamily: 'Open Sans',
      fontSize: '2.1vh',
      fontWeight: 400,
      color: '#130B1F',
      '&:hover': {
        color: '#371862'
      }
    },
    button: {
      fontFamily: 'Open Sans',
      fontSize: '12px',
      fontWeight: 600,
    },
  },
});

const App = () => {
  const [data, setData] = useState({});

  document.title = brand === 'vet' ? 'Dr. Nuvem - Resultados' : 'Dr. Tis - Resultados';

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.js">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey} language="pt-BR" scriptProps={{ async: true }}>
          <DataContext.Provider value={{ data, setData }}>
          <BrowserRouter>
            <Routes>
              <Route path="/viewer" element={<Viewer />} />
              <Route path="/viewer3d" element={<Viewer3d />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/:studyId" element={<Login />} />
              <Route index element={<Login />} />
            </Routes>
          </BrowserRouter>
          </DataContext.Provider>
        </GoogleReCaptchaProvider>
      </ThemeProvider>
    </Worker>
  );
};

export default App;
