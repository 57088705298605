import React, {  useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const ViewerModal = ({ open = true, onClose = () => null, viewerUrl = '' }) => {
  const [isOpen, setIsOpen] = useState(open);
  
  useEffect(() => {
    setIsOpen(open);
  }, [open])
    
  const handleClose = (_, reason) => {
    console.log({reason})
    if (reason === "backdropClick") return;
    setIsOpen(false);
    onClose();
  };

  if (!open) return null;

  return (
    <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        fullWidth
        maxWidth="lg"
        fullScreen
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Imagens
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{
            padding: 0,
            border: 0,
            overflow: "hidden",
        }}>
          {viewerUrl && <iframe src={viewerUrl} title="Visualizador" style={{
              width: "100%",
              height: "100%",
              border: 0,
          }} />}
        </DialogContent>
      </Dialog>
  );
};

export default ViewerModal;
